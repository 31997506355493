import React from 'react';
import PropTypes from 'prop-types';

import { Cover, GridResults, IconsAlignerService, Image, Layout, Link, SectionTextMedia, SEO, Slideshow } from 'components';
import { routes } from 'utils/routes';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './how-it-works.yml';

const HowItWorksPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Link to={routes.apart.path} {...routes.apart} className='cta-sidetab hide-mobile'>What sets us apart?</Link>

    <Cover
      title='Guaranteed straight teeth. Affordable and accessible smiles.'
      text='Your ideal smile in 5-9 months with invisible aligners sent to your door. No trips to the dentist saving you up to 70% on the clinic price.'
      image={{ url: 'pages/howitworks_cover.jpg', alt: 'Invisible braces at home' }}
      overlap
      stamp
    />

    <IconsAlignerService />

    <h2 className='section-title mb-50 mt-50'>The process</h2>

    <SectionTextMedia
      title='Free E-consultation'
      text="Start by uploading five clear photos and answer a few questions and a dentist will confirm if you're suitable."
      button={{ label: 'Get started', ...routes.getStarted }}
      image={{ url: 'pages/howitworks_econsultation.jpg', alt: 'Free e-consultation on your smartphone' }}
    />

    <SectionTextMedia
      title='SmileBox&trade;'
      hint='[Home impression kit]'
      price='howItWorks.smilebox'
      text='Use the impression kit to take and return moulds of your teeth to the lab.<br/><br/>You’ll be given medical history forms to fill out and the option to share your recent dental records (x-rays and gum scores).<br/><br/>Our experts will use your impressions to take accurate measurements of your teeth and create a 3D simulation of your predicted smile.'
      button={{ label: 'Get started', ...routes.getStarted }}
      image={{ url: 'pages/howitworks_mould.jpg', alt: 'Mould to use on teeth' }}
      videoUrl='https://www.youtube.com/watch?v=MQg15fOL_9E'
      reverse
      lightbox
      isVideo
    />

    {locale === 'uk' &&
      <SectionTextMedia
        title='Prefer a 3D scan?'
        text='If you don’t mind travelling you can instead opt for a professional 3D scan one off appointment in central London to get digital impression of your teeth.'
        image={{ url: 'pages/howitworks_scan.jpg', alt: '3D scan digital dental impression' }}
      />
    }

    <SectionTextMedia
      title='Your Straight Teeth Plan'
      text='Your assigned orthodontist will put together a personalised comprehensive orthodontic report for you that will include your diagnosis, potential limitations, predicted results and expected treatment time.'
      button={{ label: 'Get started', ...routes.getStarted }}
      image={{ url: 'pages/howitworks_plan.png', alt: 'Straight Teeth Plan and 3D Smile Simulation' }}
      videoUrl='https://www.dropbox.com/sh/iqe9quy1v1ucd3u/AAD98aC11QznLf11uabD3GoHa/Video%201/Short%20Lab%20Video%20V1.mp4'
      reverse
      lightbox
      isVideo
    />

    <SectionTextMedia
      title='StraightBox&trade;'
      hint='[Teeth straightening kit]'
      price='howItWorks.straightbox'
      text='Your personal aligners will be sent in batches according to your progress. Our clinical team will monitor your progress monthly through your app account so we can adjust your treatment if we need to.'
      button={{ label: 'Payment options', ...routes.pricing, level: 'outline' }}
      image={{ url: 'pages/howitworks_box.jpg', alt: 'Invisible aligners for visible teeth straightening results' }}
      videoUrl='https://www.dropbox.com/sh/iqe9quy1v1ucd3u/AACeKlDGu0fqkrwWSqReEtpca/Video%202/Short%20Lab%20video%202.mp4'
      reverse
      lightbox
      isVideo
    />

    <div className='aligners-wrapper fvw'>
      <div className='section-text'>
        <h2 className='mb-40'>Your aligners</h2>
        <p>Your transparent aligners will be produced through a multistep process involving 3D printing in our state-of-the-art lab.<br/><br/>They are then trimmed and hand finished to ensure maximum clarity and comfort so that you can gently straighten your smile, with minimum impact on your life.</p>
      </div>
      <Image url='pages/howitworks_aligners.jpg' alt='Invisible aligners for visible teeth straightening results' className='section-media' />
    </div>

    <SectionTextMedia
      title='Guaranteed and tracked results'
      text="Your custom aligners work in sequence and you will be recommended to wear each set for about 2 weeks for 22h a day, so you can take them out to eat and brush your teeth. This is how your smile will gradually transform.<br/><br/>Your progress will be monitored by photo analysis and you'll be updated through your app's progress tracker.<br/><br/>Most people start to see results in around 90 days and your dentist will be on hand to offer advice and support.<br/>With our unique 2 step system you will alternate between soft and hard aligners, making the process not only super effective but also more comfortable."
      button={{ label: 'Get started', ...routes.getStarted }}
      image={{ url: 'pages/howitworks_results.jpg', alt: 'Smile Upgrades' }}
      videoUrl='https://www.youtube.com/watch?v=SZNOys6rhg0'
      lightbox
      isVideo
    />

    <SectionTextMedia
      title='Upgrade & Maintain your new smile'
      text="You'll be able to upgrade to our professional strength prescription teeth whitening.<br/><br/>When you’ve got your ideal smile, you’ll want to keep it that way! That’s why at the end of your treatment you’ll be given a set of removable retainers to protect your new smile.<br/><br/>You can also join our Maintainer Plan and receive fresh retainers every 6 months to extend your Straight Teeth guarantee."
      button={{ label: 'Whitening', ...routes.teethWhitening, level: 'outline' }}
      otherButton={{ label: 'Maintainer plan', ...routes.maintainerPlan, level: 'outline' }}
      image={{ url: 'pages/howitworks_upgrade.jpg', alt: 'Smile Upgrades' }}
      reverse
    />

    <h3 className='section-title mb-50 mt-90'>#MySmileDelivered reviews</h3>

    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction />

    <h2 className='section-title mt-90 mb-50'>Who we can help</h2>

    <div className='section-icons mb-50'>
      <Slideshow>
        <div className='icon-wrapper'>
          <Image url='icons/icon_crowded.svg' alt='Invisible aligners for crowded teeth' />
          <p><b>People with crowded teeth</b></p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_spaced.svg' alt='Invisible aligners for spaced teeth' />
          <p><b>People with spaced teeth</b></p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_protruding.svg' alt='Invisible aligners for protruding teeth' />
          <p><b>People with protruding teeth</b></p>
        </div>
      </Slideshow>
    </div>
  </Layout>
);

HowItWorksPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default HowItWorksPage;
